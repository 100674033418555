.MilestoneSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;

  grid-gap: 24px;

  padding: 20px;
}

.MilestoneHomeCard {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  &__Text {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 150%;
    /* identical to box height, or 24px */

    color:rgba(255, 255, 255, 1);
  }

  img {
    width: 48px;

    border: 3px solid #ffffff;
    border-radius: 9.6px;
  }
}
