.newRewards {
  padding: 6px 18px 45px;
  &__img {
    width: 100%;
    border-radius: 4px;
    margin: 25px 0 0 0;
  }
  &__hr {
    width: 100%;
    height: 2px;
    background: #e0e0e0;
    margin: 26px 0 18px;
  }
}

.newRewardsHeader {
  h1 {
    margin: 8px 0;
    font-size: 22px;
    line-height: 130%;
    color: #000;
    margin-bottom: 8px;
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 130%;
    color: rgba(0, 0, 0, 0.6);
  }
}

.newRewardsIconHeader {
  display: flex;
  align-items: flex-start;
  margin: 16px 0 4px 0;
  img {
    margin-top: 5px;
    margin-right: 16px;
  }
  h1 {
    margin: 0;
    font-size: 16px;
    line-height: 150%;
    color: #b99e2a;
    margin-bottom: 4px;
  }
  p {
    margin: 0;
    font-size: 14px;
    line-height: 130%;
    color: rgba(0, 0, 0, 0.6);
  }
}

.bottomDrawerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 500;
  .bottomDrawer {
    width: 100vw;
    position: fixed;
    bottom: -1000px;
    transition: all 0.5s ease-in-out;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.12);
    border-radius: 12px 12px 0px 0px;
    &__header {
      padding: 26px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);
      span {
        font-size: 18px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.87);
      }
      img {
        cursor: pointer;
      }
    }
    &__content {
      padding: 16px 24px 0;
      max-height: 60vh;
      overflow: scroll;
      p {
        margin: 0;
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &__footer {
      padding: 16px 24px;
      box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
      button {
        width: 100%;
        border: none;
        padding: 12px;
        box-shadow: 0px 4px 8px rgba(49, 143, 67, 0.16);
        border-radius: 4px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
      }
    }
  }
}

.shareStudentImageCard {
  background-color: #fec200;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  &__inner {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 14px;
      line-height: 142%;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.bottomDrawerCard {
  padding: 16px;
  border-radius: 4px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__img {
    background: rgba(255, 255, 255, 0.87);
    border-radius: 5px;
    height: 50px;
    width: 50px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  &__text {
    width: 75%;
    font-size: 14px;
    line-height: 150%;
    color: #ffffff;
  }
}
