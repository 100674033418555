.Leaderboard {
  // background: url(../../Resources/images/Medals.svg);
  // background-position: 100% 0%;
  // background-repeat: no-repeat;

  &__Body {
    // margin-top: 48px;
    padding: 24px;

    &--Prize {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
    }
  }

  &__Heading {
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 150%;
    /* identical to box height, or 33px */

    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.87);
  }
  &__Subheading {
    margin-top: 8px;
    font-weight: 450;
    font-size: 1.6rem;
    line-height: 130%;

    color: rgba(0, 0, 0, 0.6);
  }
}
