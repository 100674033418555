.InsightCard {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  // display: grid;
  // grid-template-columns: 2fr 1fr;
  // align-items: center;

  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }

  &__Left {
    padding: 24px 0 24px 24px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__Text {
      &--Heading {
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 130%;
        /* or 21px */

        display: flex;
        align-items: center;

        color: #ffffff;

        margin-bottom: 12px;
      }
      &--SubHeading {
        font-style: normal;
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 130%;
        display: flex;
        align-items: center;

        color: #ffffff;

        margin-bottom: 12px;
      }
    }
    &__Button {
      padding: 10px 24px;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 4px;

      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 150%;
      /* identical to box height, or 21px */

      display: flex;
      align-items: center;

      color: #ffffff;
    }
  }
  &__Right {
    width: 100%;
  }
}
