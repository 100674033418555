@import "./main.scss";

@font-face {
  font-family: Circular;
  src: url(./Resources/font/Circular.otf);
}

html,
body,
#root,
.App {
  margin: 0;
  padding: 0;
  font-family: Circular;
  height: 100vh;
  // background: #363c9d;
}

* {
  box-sizing: border-box;
  font-size: 60%;
}

.Label {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 25px;
  color: #ffffff;
}

.Text__Arrow {
  color: white;
  font-size: 18px;

  margin-left: 8px;
}

.slick-dots {
  bottom: -50px !important;
  .slick-active {
    button {
      &::before {
        color: #009ae0 !important;
      }
    }
  }

  li {
    margin: 0 !important;
    button {
      width: unset !important;
      height: unset !important;
      padding: 0 !important;
      &::before {
        font-size: 12px !important;

        color: rgba(0, 154, 224, 0.4) !important;
      }
      &::after {
      }
    }
  }
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #cdcdcd 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;

  animation: shimmer 1s linear infinite;
  -webkit-animation: shimmer 1s linear infinite;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.fadeIn {
  height: 100%;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;

  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
