.RevenueCard {
  &__Pointer {
    position: absolute;
    z-index: -1;

    &.left {
      transform: translate(50%, -20px);
    }

    &.right {
      right: 0;
      transform: translate(-100%, -20px);
    }
  }

  &__Top {
    padding: 20px;

    background-repeat: no-repeat;
    background-size: cover;

    &--Label {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 130%;
      /* or 18px */

		color: rgba(255, 255, 255, 1);

			margin-bottom: 8px;
	}
}

&__Head {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 16px;

	&--Logo {
		display: flex;
		align-items: center;

		&--Text {
			color: rgba(255, 255, 255, 1);
			font-weight: 900;
			font-size: 2.4rem;
			line-height: 100%;
		}
	}

	&--Points {
		display: flex;
		align-items: center;
		font-style: normal;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 130%;
		/* or 18px */

		text-align: right;

		color: rgba(0, 0, 0, 0.8);

		img {
			margin-right: 4px;
		}
	}

	&--Rank {
		padding: 4px 12px;
		background-color: #00753e41;
		color: #00753e;
		border-radius: 120px;
		font-weight: 500;
		font-size: 1.6rem;
		line-height: 150%;
	}
}

&__Revenue {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;

	&--Current {
		font-weight: bold;
		font-size: 2rem;
		line-height: 100%;
	}

	&--Target {
		font-weight: normal;
		font-size: 1.4rem;
		line-height: 100%;
		align-self: flex-end;
	}
}
&__New_Footer {
	// padding: 16px 0 0;

	margin-top: 8px;

	display: flex;
	align-items: center;

	&__left {
		width: 40%;
		font-weight: 450;
		font-size: 1.4rem;
		line-height: 130%;
		/* identical to box height, or 1.4rem */

		display: flex;
		align-items: center;
		text-align: right;

		color: rgba(255, 255, 255, 1);
	}
		&__right {
			width: 60%;
			display: flex;
			font-style: normal;
			font-weight: 450;
			font-size: 1.4rem;
			line-height: 130%;
			display: flex;
			align-items: center;
			text-align: right;

		color: rgba(255, 255, 255, 1);
			justify-content: flex-end;
      align-items: center;
      span {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        color: rgba(51, 51, 51, 0.7);
      }
      &__points {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: right;

        color: #333333;
      }
    }
  }
}
