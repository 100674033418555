.Tools {
  padding: 24px 24px 60px;

  &__Top {
    margin-bottom: 24px;
    &--Heading {
      font-style: normal;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 150%;
      /* identical to box height, or 33px */

      display: flex;
      align-items: center;

      color: rgba(0, 0, 0, 0.87);
    }
    &--SubHeading {
      margin-top: 8px;
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 130%;

      color: rgba(0, 0, 0, 0.6);
    }
  }

  &__Body {
    display: grid;
    grid-template-columns: 1fr 1fr;

    grid-gap: 16px;
  }
}

.ToolCard {
  background: #1480d1;
  border-radius: 4px !important;

  background-size: 100% 100% !important;
  background-position: top left !important;

  padding: 16px;

  &--Icon {
    width: 24px;
    height: 24px;

    margin-bottom: 12px;
  }

  &__Label {
    font-style: normal;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 130%;
    /* or 150% */

    display: flex;
    align-items: center;

    color: #ffffff;

    mix-blend-mode: normal;

    margin-bottom: 16px;

    max-width: 120px;
  }

  &--Text {
    font-style: normal;
    font-weight: 450;
    font-size: 1.4rem;
    line-height: 130%;
    /* or 18px */

    color: #ffffff;

    margin-bottom: 12px;

    width: 75%;
  }

  &--Arrow {
  }
}
