.PointsCard {
  padding: 20px 20px 0;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;

    &__points {
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        div {
          margin-left: 6%;
        }
      }
      &__digit {
        font-style: normal;
        font-weight: bold;
        font-size: 2.2rem;
        line-height: 32px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;

        color: rgba(255, 255, 255, 1);
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 130%;
        /* or 18px */

        color: rgba(255, 255, 255, 1);

        margin-bottom: 8px;

        text-transform: uppercase;
      }
    }
  }
}
