.Ranking {
  margin-top: 24px;
  margin-bottom: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;

  &__Headers {
    padding: 16px 24px 17px 6px;
    display: grid;
    grid-template-columns: 1fr 2.5fr 1fr;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 14px;
    border-bottom: 1px solid #e5e5e5;
  }

  &__Block {
    padding: 16px 25px 16px 8px;
    display: grid;
    grid-template-columns: 1fr 2.5fr 1fr;
    column-gap: 8px;
  }

  &--points {
    font-size: 1.6rem;
    text-align: right;
    display: flex;
    align-items: center;
  }

  &--rank {
    font-weight: 700;
    font-size: 16px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }
  }
  &--institute {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
  }
}
