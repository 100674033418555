.loader {
  border: 4px solid #e5e5e5;
  border-radius: 50%;
  border-top: 4px solid #009ae0;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 8s linear infinite; /* Safari */
  animation: spin 8s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-left: 4px solid #e5e5e5;
  }
  50% {
    border-left: 4px solid #009ae0;
  }
  80% {
    border-bottom: 4px solid #009ae0;
  }
  100% {
    transform: rotate(360deg);
    border-left: 4px solid #e5e5e5;
    border-bottom: 4px solid #e5e5e5;
  }
}

.LoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 460px;
  height: 80vh;
}

.Error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 80vh;
}

.Error--Text {
  /* color: #ffffff; */

  font-size: 20px;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 12px;
}
.Error--Subtext {
  color: #009ae0;

  font-size: 14px;
  line-height: 150%;
  font-weight: 500;

  text-decoration: underline;
}

.NewLoader {
  width: 100%;

  padding: 16px;

  display: grid;
  place-content: center;
}

.shimmer {
  height: 490px;
  width: calc(100vw - 32px);

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.loaderWrapper {
  width: 100%;
  height: 200px;

  display: grid;
  place-content: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #333333;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
