.Header {
  padding: 14px 16px;

  font-weight: 500;
  font-size: 1.6rem;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);

  display: flex;
  align-items: center;

  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  background-color: white;
  z-index: 10;
}
