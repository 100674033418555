.ProgressBar {
  height: 8px;
  width: 100%;

  background-color: white;

  border: 0.2px solid #e5e5e5;
  border-radius: 120px;

  display: flex;
  align-items: center;

  overflow: hidden;

  span {
    display: block;
    height: 100%;
  }

  &__Indicator {
    border-radius: 4px;
    overflow: hidden;

    &--Fill {
      -webkit-animation-name: progressBar;
      animation-name: progressBar;

      -webkit-animation-duration: 2s;
      animation-duration: 2s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      animation-delay: 1.5s;
      -webkit-animation-delay: 1.5s;
    }
  }
}

@-webkit-keyframes progressBar {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
}

@keyframes progressBar {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
}
