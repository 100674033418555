.PeaknStatsCard {
  width: 100%;
  position: relative;
  display: grid;
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &_left {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    /* or 36px */

    color: rgba(255, 255, 255, 1);

    display: flex;
    align-items: center;

    padding: 29px 0 29px 20px;
  }
  &_right {
    display: flex;
    justify-content: flex-end;

    img {
      width: 100%;
    }
  }
}
