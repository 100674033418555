.Home {
  padding: 16px;
  width: 100vw;

  &__SliderButton {
    margin-top: 16px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      z-index: 1000;

      box-shadow: 0px 1.33333px 2.66667px rgba(172, 173, 175, 0.14),
        0px 2px 2.66667px rgba(172, 173, 175, 0.12),
        0px 0.666667px 3.33333px rgba(172, 173, 175, 0.2);
      border-radius: 4px;

      padding: 10px 14px;

      &:active {
        box-shadow: none;
      }
    }

    &--Prev {
    }
    &--Next {
      transform: rotate(180deg);
    }
  }
}

.CardFooter {
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;

  z-index: 10;
  &__Text {
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 130%;
    /* or 18px */

    color: rgba(255, 255, 255, 1);

    display: flex;
    justify-content: center;

    text-align: center;

    margin-bottom: 16px;
  }
  &__Button {
    width: 100%;

    background-color: #ebe0da;
    border-radius: 4px;

    height: 48px;
    margin: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    color: rgba(0, 0, 0, 0.87);

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Slide {
  height: 460px;
  position: relative;

  overflow: hidden;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;

  &__Rewards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 20px;

    img {
      margin-bottom: 20px;
      width: 100%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
    &__Text {
      &--Impact {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        /* identical to box height, or 24px */

        text-align: center;

        color: rgba(0, 0, 0, 0.87);

        margin-bottom: 8px;
      }
      &--Count {
        font-style: normal;
        font-weight: 900;
        font-size: 2.2rem;
        line-height: 150%;
        /* identical to box height, or 36px */

        text-align: center;

        color: #299e6e;
      }
    }
  }

  &__Landing {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      display: flex;
      align-items: flex-end;

      position: absolute;
      bottom: 0;
      left: -10%;
      width: 120%;
      // height: 314px;

      z-index: 2;
    }

    &--Text {
      padding: 14px 20%;

      background-color: #c8c8ff;

      position: absolute;
      bottom: 20%;

      z-index: 1;

      span {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 150%;
        /* or 21px */

        text-align: center;

        color: rgba(0, 0, 0, 0.87);

        z-index: 3;
      }
    }
  }

  &__Image {
    padding: 20px;

    img {
      max-height: 194px;
      width: 100%;

      background: #ebe3d9;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      text-align: center;

      color: rgba(0, 0, 0, 0.6);
    }
  }
}
