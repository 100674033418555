.GrowthInsights {
  padding: 24px;
  //   margin-top: 48px;

  &__Top {
    margin-bottom: 20px;
    padding: 0 0 20px 2.4rem;

    width: 100vw;
    position: relative;
    left: -2.4rem;

    background-image: url(../../Resources/images/StoreAcademy.svg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;

    border-bottom: 1px solid rgba(255, 255, 255, 0.16);

    &--Heading {
      font-style: normal;
      font-weight: 500;
      font-size: 2.2rem;
      line-height: 130%;
      /* identical to box height, or 29px */

      color: rgba(0, 0, 0, 0.87);

      margin-bottom: 12px;
    }
    &--SubHeading {
      font-style: normal;
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 130%;
      /* or 21px */

      display: flex;
      align-items: center;

      color: rgba(0, 0, 0, 0.6);
    }
  }
}
