.RewardCard{
   


border-radius: 4px;
margin-bottom: 8px;

position: relative;
img{
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    
  
    
}
}
.Reward__PointsCard{
background: #FFFFFF;
border: 1px solid #E5E5E5;
box-sizing: border-box;
border-radius: 120px;
padding: 10px 10px;
width: 120px;
display: flex;
justify-content: center;
margin: auto;
&__text{
    
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 130%;
text-align: right;
margin-right: 5px;
color: rgba(0, 0, 0, 0.87);

}
&__image{
    align-items: center;
    display: flex;
    &--coin{
        margin: auto;
        width: 10px;
    }
}
}
// .PointsCard {
//   background: #ffffff;
//   border: 1px solid #e5e5e5;
//   box-sizing: border-box;
//   border-radius: 120px;
//   padding: 10px 10px;
//   width: 120px;
//   display: flex;
//   justify-content: space-between;
//   margin: auto;
//   &__text {
//     font-style: normal;
//     font-weight: 500;
//     font-size: 12px;
//     line-height: 130%;
//     text-align: right;

//     color: rgba(0, 0, 0, 0.87);
//   }
//   &__image {
//     align-items: center;
//     display: flex;
//     &--coin {
//       margin: auto;
//       width: 10px;
//     }
//   }
// }
.RewardCard__Wrapper {
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
