.Milestone {
  padding: 2.4rem;
  // padding-top: calc(2.4rem + 48px);
  &__Body {
    .milestoneAward {
      display: flex;
      &__progress {
        width: 7px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        &__dot {
          position: absolute;
          height: 19px;
          width: 19px;
          border-radius: 50%;
        }
        img {
          position: absolute;
          margin-top: -3px;
        }
      }
      &__reward {
        margin-top: -5px;
        &__score {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          &__p1 {
            font-weight: bold;
            font-size: 24px;
            margin: 0;
          }
          &__p2 {
            margin: 0;
            padding: 6px 4px;
            border-radius: 2px;
          }
        }
        &__image {
          position: relative;
          border-radius: 4px;
          margin: 12px 0 32px;
          img {
            width: 100%;
            border-radius: 4px;
          }
          img.isWinning {
            border: 2px solid #318f43;
          }
          &__overlay {
            height: 100%;
            background: rgba(58, 129, 101, 0.8);
            border-radius: 4px;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

.MilestoneRevenue {
  border-radius: 6px;
  overflow: hidden;
  &__Top {
    background: #ddede6;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding: 2.4rem;

    &__Col1 {
      font-style: normal;
      /* identical to box height, or 24px */

      display: flex;
      align-items: center;
      justify-content: space-between;

      color: rgba(0, 0, 0, 0.87);

      margin-bottom: 20px;

      &--Label {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        img {
          margin-right: 12px;
        }
      }

      &--Rank {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        /* identical to box height, or 24px */

        display: flex;
        align-items: center;

        padding: 4px 12px;
        white-space: nowrap;

        border-radius: 50px;
      }
    }
    &__Col2 {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      &--Amount {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 100%;
        /* identical to box height, or 24px */

        display: flex;
        align-items: center;

        color: rgba(0, 0, 0, 0.87);
      }
      &--Milestone {
        font-style: normal;
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 100%;
        /* identical to box height, or 14px */

        display: flex;
        align-items: center;
        text-align: right;

        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
  &__Bottom {
    background: #30444e;
    padding: 2.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    /* identical to box height, or 21px */

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #ffffff;

    &--Left {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 150%;
    }

    &--Normal {
      font-weight: 500;
      margin: 0 4px;

      white-space: nowrap;
    }

    img {
      margin-right: 12px;
      width: 2.4rem;
    }
  }
}

.MilestoneCheckpoint {
  margin-top: 3.2rem;

  &__Top {
    &--Heading {
      font-style: normal;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 150%;

      color: rgba(0, 0, 0, 0.87);

      margin-bottom: 8px;
    }
    &--SubHeading {
      font-style: normal;
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 150%;
      /* or 24px */

      display: flex;
      align-items: center;

      color: rgba(0, 0, 0, 0.6);

      margin-bottom: 2.4rem;
    }
  }
  // &__List {
  // }
}

.MilestoneCard {
  display: flex;

  &__Left {
    position: relative;
    margin-right: 16px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    &--Bar {
      display: block;
      width: 4px;
      height: 100%;

      background-color: #dddddd;
      position: absolute;
    }

    img {
      z-index: 1;

      width: 3rem;
      border-radius: 50%;
    }
  }
  &__Right {
    width: 100%;
    &__Top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 12px;
      &--Amount {
        font-style: normal;
        font-weight: bold;
        font-size: 2rem;
        line-height: 100%;
        /* identical to box height, or 36px */

        display: flex;
        align-items: center;

        // color: #ffe470;
      }

      &--Complete {
        font-style: normal;
        font-weight: 450;
        font-size: 1.6rem;
        line-height: 100%;
        /* identical to box height, or 24px */

        display: flex;
        align-items: center;

        // color: #e4ce69;
      }
    }
    &__Bottom {
      width: 100%;
      margin-bottom: 3.2rem;
      position: relative;

      &--Image {
        display: flex;
        // position: absolute;
        // left: 0;
        // right: 0;

        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.MilestoneSuggestions {
  margin-bottom: 2.4rem;
  &__Top {
    margin-bottom: 16px;
    &--Heading {
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 125%;

      color: rgba(255, 255, 255, 1);
    }
  }

  &__List {
    background: #ffffff;
    border-radius: 4px;
    &--Item {
      display: flex;
      align-items: flex-start;
      border-radius: 4px;

      padding: 2rem 8px;
      &--Right {
        &--Text {
          font-style: normal;
          font-weight: 450;
          font-size: 1.6rem;
          line-height: 150%;
          /* or 21px */

          display: flex;
          align-items: center;

          color: rgba(0, 0, 0, 0.87);

          margin-bottom: 12px;
        }
        &--Button {
          width: fit-content;
          background: #587cd9;
          border-radius: 4px;

          padding: 8px 12px;
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 150%;
          /* identical to box height, or 21px */

          display: flex;
          align-items: center;

          color: #ffffff;
        }
      }
      img {
        margin-right: 12px;
      }
    }
  }
}

.Special {
  display: flex;
  margin-bottom: 32px;

  &__Body {
    margin-left: 16px;
    width: 100%;
    &--heading {
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.87);
    }

    &--subheading {
      margin-bottom: 12px;
      letter-spacing: 2px;
      font-weight: 450;
      font-size: 12px;
      line-height: 150%;
      color: #957b04;
    }
  }
}
